export const distritosPorSubprefeitura ={
    "subprefeituras": {
      "Aricanduva/Formosa/Carrão": [
        "Aricanduva",
        "Formosa",
        "Carrão"
      ],
      "Butantã": [
        "Butantã",
        "Jardim Bonfiglioli",
        "Jardim São Jorge",
        "Vila Sônia"
      ],
      "Campo Limpo": [
        "Campo Limpo",
        "Vila Andrade",
        "Jardim São Luís",
        "Jardim das Camélias"
      ],
      "Capela do Socorro": [
        "Capela do Socorro",
        "Jardim São Bernardo",
        "Jardim Santa Rita",
        "Parelheiros"
      ],
      "Casa Verde/Cachoeirinha": [
        "Casa Verde",
        "Cachoeirinha",
        "Vila Nova Cachoeirinha"
      ],
      "Cidade Ademar": [
        "Cidade Ademar",
        "Jardim Santa Edwiges",
        "Jardim Nilo"
      ],
      "Cidade Tiradentes": [
        "Cidade Tiradentes"
      ],
      "Ermelino Matarazzo": [
        "Ermelino Matarazzo",
        "Jardim Lídia"
      ],
      "Freguesia/Brasilândia": [
        "Freguesia do Ó",
        "Brasilândia"
      ],
      "Guaianases": [
        "Guaianases"
      ],
      "Ipiranga": [
        "Ipiranga",
        "Caminho do Mar",
        "Parque da Independência"
      ],
      "Itaim Paulista": [
        "Itaim Paulista"
      ],
      "Itaquera": [
        "Itaquera",
        "Jardim Raposo Tavares"
      ],
      "Jabaquara": [
        "Jabaquara",
        "Vila Marlene"
      ],
      "Jaçanã/Tremembé": [
        "Jaçanã",
        "Tremembé"
      ],
      "Lapa": [
        "Lapa",
        "Vila Romana",
        "Vila Leopoldina"
      ],
      "M'Boi Mirim": [
        "M'Boi Mirim",
        "Vila das Belezas"
      ],
      "Mooca": [
        "Mooca",
        "Vila Prudente"
      ],
      "Parelheiros": [
        "Parelheiros"
      ],
      "Penha": [
        "Penha",
        "Cangaíba",
        "Vila Esperança"
      ],
      "Perus/Anhanguera": [
        "Perus",
        "Anhanguera"
      ],
      "Pinheiros": [
        "Pinheiros",
        "Vila Madalena",
        "Alto de Pinheiros"
      ],
      "Pirituba/Jaraguá": [
        "Pirituba",
        "Jaraguá"
      ],
      "Santana/Tucuruvi": [
        "Santana",
        "Tucuruvi"
      ],
      "Santo Amaro": [
        "Santo Amaro",
        "Vila São Paulo"
      ],
      "São Mateus": [
        "São Mateus"
      ],
      "São Miguel": [
        "São Miguel"
      ],
      "Sapopemba": [
        "Sapopemba"
      ],
      "Sé": [
        "Sé"
      ],
      "Vila Mariana": [
        "Vila Mariana",
        "Vila Clementino"
      ],
      "Vila Maria/Vila Guilherme": [
        "Vila Maria",
        "Vila Guilherme"
      ],
      "Vila Prudente": [
        "Vila Prudente"
      ]
    }
  }
  