import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Collapse, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { URLs } from "../../env";

interface SituUsoUniFormProps {
    id: string;
    idLocal?: string,
    arrolamento_id?: string
    regime_ocupacao?: string
    valor_locacao?: string
    nsi_valor_locacao?: boolean
    contrato_locacao?: string
    nome_proprietario?: string
    contato_proprietario?: string
    nsi_contato_proprietario?: boolean
}


const SituUsoUniForm: React.FC<SituUsoUniFormProps> = ({
    id,
    idLocal,
    arrolamento_id = id,
    contato_proprietario,
    contrato_locacao,
    nome_proprietario,
    nsi_contato_proprietario,
    nsi_valor_locacao,
    regime_ocupacao,
    valor_locacao
}) => {
    const [regimeOcupacao, setRegimeOcupacao] = useState(regime_ocupacao? regime_ocupacao : '');
    const [dados, setDados] = useState({
        regime_ocupacao: '',
        valor_locacao: valor_locacao ? valor_locacao : '',
        nsi_valor_locacao: nsi_valor_locacao ? nsi_valor_locacao : false,
        contrato_locacao: contrato_locacao ? contrato_locacao : '',
        nome_proprietario: nome_proprietario ? nome_proprietario : '',
        contato_proprietario: contato_proprietario ? contato_proprietario : '',
        nsi_contato_proprietario: nsi_contato_proprietario ? nsi_contato_proprietario : false,
        
        naoSabeContratoLocacao: false,
        idLocal: idLocal ? idLocal : "not",
        arrolamento_id: id,
    });
    
    dados.arrolamento_id = id;
    const [mostrarContatos, setMostrarContatos] = useState(false);
    const handleSave = async () => {
        dados.regime_ocupacao = regimeOcupacao;
        try {
            if (dados.idLocal === "not") {
                await axios.post(`${URLs.DEV}/situ-uso-uni-3-arrolamento/`, dados, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                alert('Dados salvos com sucesso!');
            } else {
                await axios.put(`${URLs.DEV}/situ-uso-uni-3-arrolamento/${dados.idLocal}`, dados, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                alert('Dados editados com sucesso!');
            }
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
            alert('Erro ao salvar os dados');
        }
    };

    const handleChangeRegime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegimeOcupacao(event.target.value);
        setDados({
            valor_locacao: '',
            contrato_locacao: '',
            nome_proprietario: '',
            contato_proprietario: '',
            nsi_valor_locacao: false,
            naoSabeContratoLocacao: false,
            nsi_contato_proprietario: false,
            arrolamento_id: '',
            idLocal: idLocal ? idLocal :"not",
            regime_ocupacao: ''
        });
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setDados((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setDados((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    return (
        <>
            <div
                className="section-header"
                onClick={() => setMostrarContatos(!mostrarContatos)}
                style={{ cursor: 'pointer', backgroundColor: '#f1f1f1', padding: '10px', marginBottom: '10px', marginTop: '5px', borderRadius: '15px' }}
            >
                3. SITUAÇÃO E USO DA UNIDADE
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        backgroundColor: '#8b8b8b',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#797979',
                        },
                        minWidth: '30px',
                        padding: '5px',
                        fontSize: '12px',
                        marginLeft: '15px',
                    }}
                >
                    {mostrarContatos ? 'Esconder Formulário' : 'Mostrar Formulário'}
                </Button>
            </div>
            <Collapse in={mostrarContatos}>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                <FormLabel component="legend">Regime de Ocupação da Unidade</FormLabel>
                    <FormControl component="fieldset" fullWidth>
                        {/* <FormLabel component="legend">Regime de Ocupação da Unidade</FormLabel> */}
                        <RadioGroup name="regimeOcupacao" value={regimeOcupacao} onChange={handleChangeRegime}>
                            <FormControlLabel value="alugada" control={<Radio />} label="Alugada" />
                            <FormControlLabel value="cedida" control={<Radio />} label="Cedida / emprestada" />
                            <FormControlLabel value="ocupada" control={<Radio />} label="Ocupada / habitada" />
                            <FormControlLabel value="propria" control={<Radio />} label="Própria" />
                            <FormControlLabel value="naoSabeInformar" control={<Radio />} label="Não sabe informar" />
                        </RadioGroup>
                    </FormControl>
                    {regimeOcupacao === 'alugada' && (
                        <Collapse in={regimeOcupacao === 'alugada'}>
                            <Grid container spacing={3}>
                                {/* Valor do aluguel */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Valor do aluguel (R$)"
                                        type="text"
                                        name="valor_locacao"
                                        value={dados.valor_locacao}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={dados.nsi_valor_locacao}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                name="nsi_valor_locacao"
                                                checked={dados.nsi_valor_locacao}
                                                onChange={handleCheckboxChange}
                                            />
                                        }
                                        label="Não sabe informar"
                                    />
                                </Grid>

                                {/* Contrato de locação */}
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Contrato de locação</FormLabel>
                                    <RadioGroup
                                        name="contrato_locacao"
                                        value={dados.contrato_locacao}
                                        onChange={handleChange}
                                        row
                                    >
                                        <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                                        <FormControlLabel value="nao" control={<Radio />} label="Não" />
                                        <FormControlLabel
                                            value="naoSabeInformar"
                                            control={<Radio />}
                                            label="Não sabe informar"
                                            onChange={(e: any) =>
                                                setDados((prev) => ({
                                                    ...prev,
                                                    naoSabeContratoLocacao: e.target.value === "naoSabeInformar",
                                                }))
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>

                                {/* Nome do proprietário */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Nome completo do proprietário da unidade"
                                        type="text"
                                        name="nome_proprietario"
                                        value={dados.nome_proprietario}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>

                                {/* Contato do proprietário */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Contato do proprietário da unidade (Celular)"
                                        type="text"
                                        name="contato_proprietario"
                                        value={dados.contato_proprietario}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled={dados.nsi_contato_proprietario}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                name="nsi_contato_proprietario"
                                                checked={dados.nsi_contato_proprietario}
                                                onChange={handleCheckboxChange}
                                            />
                                        }
                                        label="Não sabe informar"
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>
                    )}
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

export default SituUsoUniForm;
