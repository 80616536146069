import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Typography, Box, Collapse } from '@mui/material';
import axios from 'axios';
import { URLs } from '../../env';

interface Anexo {
    id: number;
    nome: string;
    base64: string | null;
}

const AnexosComponent: React.FC<{ id: string }> = ({ id }) => {
    // Estado para armazenar os blocos de anexos (RG, CPF, etc.)
    const [anexos, setAnexos] = useState<{ [key: string]: Anexo[] }>({
        RG: [],
        CPF: [],
        Certidoes: [],
        CNH: [],
        Outros: [],
        RNM: [],
        CRNM: [],
        ProcRegNaciMigra: [],
        Passaport: [],
        Declaracoes: [],
        CertiInterdicaoTotal: [],
        CertiInterdicaoParcial: []
    });
    const [selagemId, setSelagemId] = useState('');
    useEffect(() => {
        setSelagemId(id); // Atualiza selagemId sempre que o id mudar
      }, [id]);
    const [mostrarIdentificacao, setMostrarIdentificacao] = useState(false);
console.log(id);

    // Função para adicionar um novo anexo em um bloco específico
    const handleAddAnexo = (tipoDocumento: string, base64: string, nomeArquivo: string) => {
        setAnexos((prev) => ({
            ...prev,
            [tipoDocumento]: [
                ...prev[tipoDocumento],
                { id: Date.now(), nome: nomeArquivo || 'Novo Documento', base64 }
            ]
        }));
    };

    // Função para renomear um anexo
    const handleRenameAnexo = (tipoDocumento: string, id: number, novoNome: string) => {
        setAnexos((prev) => ({
            ...prev,
            [tipoDocumento]: prev[tipoDocumento].map((anexo) =>
                anexo.id === id ? { ...anexo, nome: novoNome } : anexo
            )
        }));
    };

    // Função para remover um anexo
    const handleRemoveAnexo = (tipoDocumento: string, id: number) => {
        setAnexos((prev) => ({
            ...prev,
            [tipoDocumento]: prev[tipoDocumento].filter((anexo) => anexo.id !== id)
        }));
    };

    // Função para converter arquivo em base64
    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    // Função para anexar fotos pela câmera (web)
    const handleCameraUpload = async (tipoDocumento: string) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.capture = 'camera';
        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                try {
                    const base64 = await convertFileToBase64(file);
                    handleAddAnexo(tipoDocumento, base64, file.name);
                } catch (error) {
                    console.error('Erro ao converter o arquivo para base64', error);
                }
            }
        };
        input.click();
    };

    // Função para anexar um arquivo selecionado
    const handleFileUpload = async (tipoDocumento: string) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.jpeg, .jpg, .png';  // Permitir apenas imagens
        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                try {
                    const base64 = await convertFileToBase64(file);
                    handleAddAnexo(tipoDocumento, base64, file.name);
                } catch (error) {
                    console.error('Erro ao converter o arquivo para base64', error);
                }
            }
        };
        input.click();
    };
    // Função para enviar os anexos ao backend
    const handleEnviarAnexos = async () => {
        // Organizando os anexos no formato necessário para o backend
        const payload = {
            selagem_id: selagemId,  // Garantindo que o selagem_id esteja no payload
            anexos: Object.entries(anexos).map(([tipoDocumento, arquivos]) => ({
                tipoDocumento,
                arquivos: arquivos.map(anexo => ({
                    id: anexo.id,  // Incluir o ID do anexo
                    nome: anexo.nome,
                    base64: anexo.base64,
                }))
            }))
        };
    
        try {
            await axios.post(`${URLs.DEV}/anexos-8-selagem/`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            alert('Dados salvos com sucesso!');
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
            alert('Dados salvos com sucesso!'); //voltar aqui
        }
    };

    return (
        <>
            <div
                className="section-header"
                onClick={() => setMostrarIdentificacao(!mostrarIdentificacao)}
                style={{ cursor: 'pointer', backgroundColor: '#f1f1f1', padding: '10px', marginBottom: '10px', borderRadius: '15px' }}
            >
                8. ANEXOS
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        backgroundColor: '#8b8b8b',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#797979',
                        },
                        minWidth: '30px',
                        padding: '5px',
                        fontSize: '12px',
                        marginLeft: '15px',
                    }}
                >
                    {mostrarIdentificacao ? 'Esconder Formulário' : 'Mostrar Formulário'}
                </Button>
            </div>
            <Collapse in={mostrarIdentificacao}>
                <Grid container spacing={3}>
                    {/* Bloco de Anexos para RG */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - RG</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('RG')}>Anexar RG</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('RG')}>Câmera</Button>
                        </Box>
                        {anexos.RG.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('RG', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('RG', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    {/* Bloco de Anexos para CPF */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - CPF</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('CPF')}>Anexar CPF</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('CPF')}>Câmera</Button>
                        </Box>

                        {anexos.CPF.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('CPF', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('CPF', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    {/* Bloco de Anexos para Certidoes */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - Certidões</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('Certidoes')}>Anexar Certidões</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('Certidoes')}>Câmera</Button>
                        </Box>

                        {anexos.Certidoes.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('Certidoes', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('Certidoes', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    {/* Bloco de Anexos para CNH */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - CNH</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('CNH')}>Anexar CNH</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('CNH')}>Câmera</Button>
                        </Box>

                        {anexos.CNH.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('CNH', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('CNH', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    {/* Bloco de Anexos para Outros */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - Outros</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('Outros')}>Anexar Outros</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('Outros')}>Câmera</Button>
                        </Box>

                        {anexos.Outros.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('Outros', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('Outros', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    {/* Bloco de Anexos para RNM */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - RNM</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('RNM')}>Anexar RNM</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('RNM')}>Câmera</Button>
                        </Box>

                        {anexos.RNM.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('RNM', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('RNM', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    {/* Bloco de Anexos para CRNM */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - CRNM</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('CRNM')}>Anexar CRNM</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('CRNM')}>Câmera</Button>
                        </Box>

                        {anexos.CRNM.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('CRNM', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('CRNM', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>  

                    {/* Bloco de Anexos para ProcRegNaciMigra */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - Protocolo de Registro Nacional Migratorio</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('ProcRegNaciMigra')}>Anexar Prot. Reg. Nac. Migratorio</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('ProcRegNaciMigra')}>Câmera</Button>
                        </Box>

                        {anexos.ProcRegNaciMigra.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('ProcRegNaciMigra', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('ProcRegNaciMigra', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>   

                    {/* Bloco de Anexos para Passaport */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - Passaporte</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('Passaport')}>Anexar Passaporte</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('Passaport')}>Câmera</Button>
                        </Box>

                        {anexos.Passaport.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('Passaport', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('Passaport', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    {/* Bloco de Anexos para Declaracoes */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - Declarações</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('Declaracoes')}>Anexar Declarações</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('Declaracoes')}>Câmera</Button>
                        </Box>

                        {anexos.Declaracoes.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('Declaracoes', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('Declaracoes', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                    
                    {/* Bloco de Anexos para CertiInterdicaoTotal */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - Certificado de Interdição Total</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('CertiInterdicaoTotal')}>Anexar Certificado de Interdição Total</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('CertiInterdicaoTotal')}>Câmera</Button>
                        </Box>

                        {anexos.CertiInterdicaoTotal.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('CertiInterdicaoTotal', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('CertiInterdicaoTotal', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>  

                    {/* Bloco de Anexos para  CertiInterdicaoParcial */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Anexos - Certificado de Interdição Parcial</Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button variant="contained" onClick={() => handleFileUpload('CertiInterdicaoParcial')}>Anexar Certificado de Interdição Parcial</Button>
                            <Button variant="contained" onClick={() => handleCameraUpload('CertiInterdicaoParcial')}>Câmera</Button>
                        </Box>

                        {anexos.CertiInterdicaoParcial.map((anexo) => (
                            <>
                                <br />
                                <Grid container key={anexo.id} spacing={2} alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            label="Nome do Documento"
                                            value={anexo.nome}
                                            onChange={(e) => handleRenameAnexo('CertiInterdicaoParcial', anexo.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="outlined" color="error" onClick={() => handleRemoveAnexo('CertiInterdicaoParcial', anexo.id)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>                  
                    <Grid item xs={12}>
___________________________________________________________________________________________________
                    </Grid>
                    {/* Botão para enviar todos os anexos */}
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleEnviarAnexos}>
                            Enviar Anexos
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
___________________________________________________________________________________________________
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

export default AnexosComponent;
