import { Button, Grid, Collapse, RadioGroup, FormControlLabel, Radio, FormLabel, TextField, Checkbox, FormControl, FormGroup } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { URLs } from "../../env";

interface Dados {
    temDeficiencia: string;
    possuiLaudo: string;
    tiposDeficiencia: string[];
    utilizaTecnologiaAssistida: string;
    tecnologiasAssistivas: string[];
    tecnologiaEspecifica: string;
    arrolamento_id: string;
    idLocal?: string,
}


interface PessoaDefFormProps {
    id: string;
    idLocal?: string,
    arrolamento_id?: string
    temDeficiencia?: string
    possuiLaudo?: string
    tiposDeficiencia?: Array<string>
    utilizaTecnologiaAssistida?: string
    tecnologiasAssistivas?: Array<string>
    tecnologiaEspecifica?: string
}


// Tipo para garantir que apenas chaves válidas de Dados sejam usadas
type DadosKey = keyof Dados;

const PessoaDefForm: React.FC<PessoaDefFormProps> = ({
    id,
    idLocal,
    arrolamento_id = id,
    possuiLaudo,
    tecnologiaEspecifica,
    tecnologiasAssistivas,
    temDeficiencia,
    tiposDeficiencia,
    utilizaTecnologiaAssistida
}) => {
    const [dados, setDados] = useState({
        temDeficiencia: temDeficiencia ? temDeficiencia : '',
        possuiLaudo: possuiLaudo ? possuiLaudo : '',
        tiposDeficiencia: tiposDeficiencia ? tiposDeficiencia : [],
        utilizaTecnologiaAssistida: utilizaTecnologiaAssistida ? utilizaTecnologiaAssistida : '',
        tecnologiasAssistivas: tecnologiasAssistivas ? tecnologiasAssistivas : [],
        tecnologiaEspecifica: tecnologiaEspecifica ? tecnologiaEspecifica : '',
        idLocal: idLocal ? idLocal : "not",
        arrolamento_id: id,
    });
    dados.arrolamento_id = id;

    const [mostrarIdentificacao, setMostrarIdentificacao] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target as { name: DadosKey; value: string };
        setDados((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target as { name: DadosKey; value: string };
        setDados((prev) => {
            const currentValues = prev[name];

            // Verifica se currentValues é um array
            if (Array.isArray(currentValues)) {
                const newValues = currentValues.includes(value)
                    ? currentValues.filter((item) => item !== value) // Remove if already present
                    : [...currentValues, value]; // Add if not present

                return {
                    ...prev,
                    [name]: newValues,
                };
            }

            // Se não for um array (deve ser uma string), apenas retorna o estado anterior
            return prev;
        });
    };

    const handleSave = async () => {
        try {
            if (dados.idLocal === "not") {
                await axios.post(`${URLs.DEV}/pessoa-def-7-arrolamento/`, dados, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                alert('Dados salvos com sucesso!');
            } else {
                await axios.put(`${URLs.DEV}/pessoa-def-7-arrolamento/${dados.idLocal}`, dados, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                alert('Dados editados com sucesso!');
            }
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
            alert('Erro ao salvar os dados');
        }
    };

    return (
        <>
            <div
                className="section-header"
                onClick={() => setMostrarIdentificacao(!mostrarIdentificacao)}
                style={{ cursor: 'pointer', backgroundColor: '#f1f1f1', padding: '10px', marginBottom: '10px', borderRadius: '15px' }}
            >
                7. PESSOA COM DEFICIÊNCIA
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        backgroundColor: '#8b8b8b',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#797979',
                        },
                        minWidth: '30px',
                        padding: '5px',
                        fontSize: '12px',
                        marginLeft: '15px',
                    }}
                >
                    {mostrarIdentificacao ? 'Esconder Formulário' : 'Mostrar Formulário'}
                </Button>
            </div>
            <Collapse in={mostrarIdentificacao}>
                <Grid container spacing={3}>
                    {/* Campo você tem deficiência? */}
                    <Grid item xs={12}>
                        <FormLabel component="legend">Você tem deficiência?</FormLabel>
                        <RadioGroup
                            name="temDeficiencia"
                            value={dados.temDeficiencia}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                            <FormControlLabel value="nao" control={<Radio />} label="Não" />
                            <FormControlLabel value="naoSabe" control={<Radio />} label="Não sabe informar" />
                            <FormControlLabel value="naoRespondeu" control={<Radio />} label="Não respondeu" />
                        </RadioGroup>
                    </Grid>

                    {/* Campo possui laudo médico */}
                    {dados.temDeficiencia === 'sim' && (
                        <Grid item xs={12}>
                            <FormLabel component="legend">Possui laudo médico?</FormLabel>
                            <RadioGroup
                                name="possuiLaudo"
                                value={dados.possuiLaudo}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                                <FormControlLabel value="nao" control={<Radio />} label="Não" />
                                <FormControlLabel value="naoSabe" control={<Radio />} label="Não sabe informar" />
                                <FormControlLabel value="naoRespondeu" control={<Radio />} label="Não respondeu" />
                            </RadioGroup>
                        </Grid>
                    )}

                    {/* Campo qual/quais os tipos de deficiência */}
                    {dados.temDeficiencia === 'sim' && (
                        <Grid item xs={12}>
                            <FormLabel component="legend">Qual/quais os tipos de deficiência?</FormLabel>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {[
                                        'Física',
                                        'Visual',
                                        'Auditiva',
                                        'Intelectual/Mental',
                                        'Múltiplas',
                                        'Mobilidade Reduzida',
                                        'Surdo-cegueira',
                                        'Outra'
                                    ].map((tipo) => (
                                        <FormControlLabel
                                            key={tipo}
                                            control={
                                                <Checkbox
                                                    checked={dados.tiposDeficiencia.includes(tipo)}
                                                    onChange={handleCheckboxChange}
                                                    name="tiposDeficiencia"
                                                    value={tipo}
                                                />
                                            }
                                            label={tipo}
                                        />
                                    ))}
                                </FormGroup>
                                <TextField
                                    label="Especificar outro tipo"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    disabled={!dados.tiposDeficiencia.includes('Outra')}
                                    onChange={(e) => setDados(prev => ({ ...prev, tecnologiaEspecifica: e.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                    )}

                    {/* Campo utiliza tecnologia assistida */}
                    <Grid item xs={12} visibility={dados.temDeficiencia === 'sim' ? "visible" : "collapse"}>
                        <FormLabel component="legend">Utiliza tecnologia assistiva?</FormLabel>
                        <RadioGroup
                            name="utilizaTecnologiaAssistida"
                            value={dados.utilizaTecnologiaAssistida}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                            <FormControlLabel value="nao" control={<Radio />} label="Não" />
                        </RadioGroup>
                    </Grid>

                    {/* Campo Qual Tecnologia Assistiva Utiliza? */}
                    {dados.utilizaTecnologiaAssistida === 'sim' && dados.temDeficiencia === 'sim' && (
                        <Grid item xs={12}>
                            <FormLabel component="legend">Qual tecnologia assistiva utiliza?</FormLabel>
                            <FormGroup>
                                {[
                                    'Aparelho Auditivo',
                                    'Órteses/Próteses',
                                    'Cadeira de Rodas',
                                    'Cama Hospitalar',
                                    'Muleta/Bengala 4 Pontas/Andador',
                                    'Lupas/Lentes/Fonte Ampliadas ou Contraste',
                                    'Braille ou Equipamento com Síntese de Voz',
                                    'Libras',
                                    'Leitura Facial/Labial',
                                    'Bengala Branca ou Verde Vermelha',
                                    'Cão Guia',
                                    'Prancha de Comunicação Alternativa',
                                    'Outro'
                                ].map((tecnologia) => (
                                    <div key={tecnologia}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={dados.tecnologiasAssistivas.some((item) => item.startsWith(tecnologia))}
                                                    onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        setDados((prev) => {
                                                            const updated = checked
                                                                ? [...prev.tecnologiasAssistivas, `${tecnologia} - 0`] // Default value
                                                                : prev.tecnologiasAssistivas.filter((item) => !item.startsWith(tecnologia));
                                                            return { ...prev, tecnologiasAssistivas: updated };
                                                        });
                                                    }}
                                                    name="tecnologiasAssistivas"
                                                    value={tecnologia}
                                                />
                                            }
                                            label={tecnologia}
                                        />
                                        {dados.tecnologiasAssistivas.some((item) => item.startsWith(tecnologia)) && (
                                            <TextField
                                                type="number"
                                                label={`Quantidade de ${tecnologia}`}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                value={
                                                    dados.tecnologiasAssistivas.find((item) => item.startsWith(tecnologia))?.split(' - ')[1] || ''
                                                }
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setDados((prev) => ({
                                                        ...prev,
                                                        tecnologiasAssistivas: prev.tecnologiasAssistivas.map((item) =>
                                                            item.startsWith(tecnologia) ? `${tecnologia} - ${value}` : item
                                                        ),
                                                    }));
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </FormGroup>
                            <TextField
                                label="Especificar outro"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                disabled={!dados.tecnologiasAssistivas.some((item) => item.startsWith('Outro'))}
                                onChange={(e) => setDados((prev) => ({ ...prev, tecnologiaEspecifica: e.target.value }))}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

export default PessoaDefForm;
