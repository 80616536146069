import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Collapse } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { URLs } from "../../env";

interface SituUsoUniFormProps {
    id: string;
    idLocal?: string,
    selagem_id?: string
    situacao?: string
    uso?: string
    usoNaoIdentificado?: string
  }

const SituUsoUniForm: React.FC<SituUsoUniFormProps> = ({ 
    id,
    idLocal,
    selagem_id = id,
    situacao,
    uso,
    usoNaoIdentificado,
 }) => {
    const [dados, setDados] = useState({
        situacao: situacao ? situacao : '',
        uso: uso ? uso : '',
        usoNaoIdentificado: usoNaoIdentificado ? usoNaoIdentificado : '',
        idLocal: idLocal ? idLocal : "not",
        selagem_id: id
    });
    dados.selagem_id = id;
    const [mostrarContatos, setMostrarContatos] = useState(false);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setDados((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const mostrarUsoNaoIdentificado = () => {
        return ['Em construção/(vazio)', 'Em reforma/(vazio)', 'Unidade vazia'].includes(dados.situacao);
    };
    const handleSave = async () => {
        try {
            if (dados.idLocal === "not") {
            const response = await axios.post(`${URLs.DEV}/situ-uso-uni-3-selagem/`, dados, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            alert('Dados salvos com sucesso!');
        } else {
            const response = await axios.put(`${URLs.DEV}/situ-uso-uni-3-selagem/${dados.idLocal}`, dados, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            alert('Dados editados com sucesso!');
        }
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
            alert('Erro ao salvar os dados');
        }
    };

    return (
        <>
            <div
                className="section-header"
                onClick={() => setMostrarContatos(!mostrarContatos)}
                style={{ cursor: 'pointer', backgroundColor: '#f1f1f1', padding: '10px', marginBottom: '10px', marginTop: '5px', borderRadius: '15px' }}
            >
                3. SITUAÇÃO E USO DA UNIDADE
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        backgroundColor: '#8b8b8b',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#797979',
                        },
                        minWidth: '30px',
                        padding: '5px',
                        fontSize: '12px',
                        marginLeft: '15px',
                    }}
                >
                    {mostrarContatos ? 'Esconder Formulário' : 'Mostrar Formulário'}
                </Button>
            </div>
            <Collapse in={mostrarContatos}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="situacao-label"
                                sx={{ backgroundColor: 'white', padding: '0 4px' }}
                            >Situação e Uso da Unidade</InputLabel>
                            <Select
                                labelId="situacao-label"
                                name="situacao"
                                value={dados.situacao}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>
                                <MenuItem value="Ocupado(Habitado)">Ocupado(Habitado)</MenuItem>
                                <MenuItem value="Em construção/(vazio)">Em construção/(vazio)</MenuItem>
                                <MenuItem value="Em construção/(habitado)">Em construção/(habitado)</MenuItem>
                                <MenuItem value="Em reforma/(vazio)">Em reforma/(vazio)</MenuItem>
                                <MenuItem value="Em reforma/(habitado)">Em reforma/(habitado)</MenuItem>
                                <MenuItem value="Não identificado">Não identificado</MenuItem>
                                <MenuItem value="Unidade vazia">Unidade vazia</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="usoUnidade-label"
                                sx={{ backgroundColor: 'white', padding: '0 4px' }}
                            >Uso da Unidade</InputLabel>
                            <Select
                                labelId="usoUnidade-label"
                                name="uso"
                                value={dados.uso}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>
                                <MenuItem value="Residencial">Residencial</MenuItem>
                                <MenuItem value="Comercial">Comercial</MenuItem>
                                <MenuItem value="Uso Misto">Uso Misto</MenuItem>
                                <MenuItem value="Institucional">Institucional</MenuItem>
                                <MenuItem value="Serviço">Serviço</MenuItem>
                                <MenuItem value="Industrial">Industrial</MenuItem>
                                <MenuItem value="Outros">Outros</MenuItem>
                                {mostrarUsoNaoIdentificado() && (
                                    <MenuItem value="Uso não Identificado">Uso não Identificado</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    {dados.uso === "Outros" && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Descreva o uso da unidade"
                                name="usoNaoIdentificado"
                                value={dados.usoNaoIdentificado}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

export default SituUsoUniForm;
